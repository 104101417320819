import { clientMount } from '@vp/ubik-fragment-react'
import type { MountWithRootOptions } from '@vp/ubik-fragment-types'
import { Fragment, type Props } from '../components/fragment'
import authConfig from '../common/authConfig'
import { IdentityProvider } from '@vp/ubik-context'
import { LocalizationsProvider } from '../hooks/useLocalizations'

export const mount: MountWithRootOptions<Props> = async (rootElement, renderProps, rootOptions) => {
  const { locale, localizations } = renderProps
  const component = (
    <IdentityProvider auth={authConfig(locale)}>
      <LocalizationsProvider localizations={localizations}>
        <Fragment {...renderProps} />
      </LocalizationsProvider>
    </IdentityProvider>

  )
  return clientMount(component, rootElement, rootOptions)
}
