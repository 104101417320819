import { useContext, useState } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { Spinner, Link, StandardForm, Button, Typography } from '@vp/swan'
import FormTextBox from '../../Shared/FormTextBox'
import { TabContext } from '../../TabContext'
import { useLocalization } from '../../../../../hooks/useLocalizations'

const isEmailValid = (email: string) => isEmail(email)
const isEmailSame = (newEmail = '', confirmEmail = '') =>
  newEmail.toLocaleLowerCase() === confirmEmail.toLocaleLowerCase()

type UpdateEmailFormProps = {
  email: string;
  onSave: (email: string) => any;
  hide: () => void;
  savingEmail: boolean;
}

const UpdateEmailForm = (props: UpdateEmailFormProps) => {
  const { email } = props

  const { t } = useLocalization()
  const { setSectionAltered } = useContext(TabContext)

  const [newEmail, setNewEmail] = useState({
    value: '',
    isValid: false,
    inFocus: true,
  })
  const [confirmEmail, setConfirmEmail] = useState({
    value: '',
    isSame: false,
    inFocus: true,
  })

  const emailChange = (value: string) => {
    const isValid = isEmailValid(value)
    setSectionAltered(value !== '')
    setNewEmail({ value, isValid, inFocus: true })
  }

  const confirmEmailChange = (value: string, inFocus = true) => {
    const isSame = isEmailSame(newEmail.value, value)
    setConfirmEmail({ value, isSame, inFocus })
  }

  const onEmailBlur = () => {
    if (!confirmEmail.inFocus) {
      confirmEmailChange(confirmEmail.value, false)
    }
    setNewEmail({ ...newEmail, inFocus: false })
  }

  const shouldSave = () => {
    const newEmailValue = newEmail.value
    return (
      isEmailValid(newEmailValue) &&
      isEmailSame(newEmailValue, confirmEmail.value) &&
      newEmailValue !== email
    )
  }

  const onEmailSave = async (event: any) => {
    event.preventDefault()

    const result = await props.onSave(newEmail.value)

    return result
  }

  const emailIsValid = () => newEmail.isValid || newEmail.inFocus
  const isSameEmail = () => confirmEmail.isSame || confirmEmail.inFocus

  return (
    <StandardForm className='update-email' noValidate>
      <FormTextBox
        data-testid='email-change-input'
        label={t('personalInformation.input.newEmail')}
        value={newEmail.value}
        onChange={(event: { target: { value: string } }) =>
          emailChange(event.target.value)}
        onBlur={onEmailBlur}
        validator={emailIsValid}
        errorText={t('personalInformation.input.emailValidation')}
      />
      <FormTextBox
        data-testid='confirm-change-input'
        label={t('personalInformation.input.confirmEmail')}
        value={confirmEmail.value}
        onChange={(event: { target: { value: string } }) =>
          confirmEmailChange(event.target.value)}
        onBlur={() => setConfirmEmail({ ...confirmEmail, inFocus: false })}
        onFocus={() => confirmEmailChange(confirmEmail.value)}
        validator={isSameEmail}
        errorText={t('personalInformation.input.confirmEmailValidation')}
      />
      <Button
        mr='between-actions'
        skin='primary'
        type='submit'
        disabled={!shouldSave() || props.savingEmail}
        onClick={onEmailSave}
      >
        {props.savingEmail && (
          <Spinner accessibleText={t('loading')} size='tiny' />
        )}
        {t('personalInformation.save')}
      </Button>
      <Link
        component='button'
        className='link-text'
        onClick={(e: any) => {
          e.preventDefault()
          props.hide()
        }}
      >
        <Typography textAlign='center'>
          {t('personalInformation.cancel')}
        </Typography>
      </Link>
    </StandardForm>
  )
}

export default UpdateEmailForm
