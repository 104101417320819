import { getParam } from './EnvironmentHelpers'

const discountPerCountry: Record<
  string,
  Record<string, { startDate: string; discount: string; endDate?: string }[]>
> = {
  vistaprint: {
    AU: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '0',
      },
    ],
    CA: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '15',
      },
    ],
    ES: [
      {
        startDate: '2023-01-01',
        endDate: '2023-03-07',
        discount: '5',
      },
      {
        startDate: '2023-03-08',
        discount: '15',
      },
    ],
    FR: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '15',
      },
    ],
    GB: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '15',
      },
    ],
    IE: [
      {
        startDate: '2023-01-01',
        endDate: '2023-03-05',
        discount: '10',
      },
      {
        startDate: '2023-03-06',
        discount: '15',
      },
    ],
    IT: [
      {
        startDate: '2023-01-01',
        endDate: '2023-03-07',
        discount: '5',
      },
      {
        startDate: '2023-03-08',
        discount: '15',
      },
    ],
    NZ: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '0',
      },
    ],
    PT: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '10',
      },
    ],
    US: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '15',
      },
    ],
    DE: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '10',
      },
    ],
    CH: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '10',
      },
    ],
    AT: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '10',
      },
    ],
    BE: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '10',
      },
    ],
    NL: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '10',
      },
    ],
    DK: [
      {
        startDate: '2023-01-01',
        endDate: '2023-03-03',
        discount: '10',
      },
      {
        startDate: '2023-03-07',
        discount: '15',
      },
    ],
    SE: [
      {
        startDate: '2023-01-01',
        endDate: '2099-01-01',
        discount: '15',
      },
    ],
    FI: [
      {
        startDate: '2023-01-01',
        endDate: '2023-03-05',
        discount: '10',
      },
      {
        startDate: '2023-03-06',
        discount: '15',
      },
    ],
    NO: [
      {
        startDate: '2023-01-01',
        endDate: '2023-03-06',
        discount: '10',
      },
      {
        startDate: '2023-03-07',
        discount: '15',
      },
    ],
  },
}

export const getDiscountPerCountry = (tenant: string, country: string) => {
  // Whenever there is any need to add discount for new date, add new object at last and remove the object at first
  const countryData = discountPerCountry[tenant]?.[country] || []
  const paramDate = getParam('debugCurrentDate')
  const currentDate = paramDate ? new Date(paramDate) : new Date()

  return countryData.find(
    (u) => currentDate >= new Date(u.startDate) && (u.endDate ? currentDate <= new Date(u.endDate) : true)
  )?.discount
}
