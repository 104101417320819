import React from 'react'
import { List, ListItem, Typography } from '@vp/swan'
import passwordPolicy from './passwordPolicy'
import { useLocalization } from '../../../../../hooks/useLocalizations'

const MINIMUM_PASSWORD_LENGTH = 8
const MINIMUM_UPPERCASE_LETTER_COUNT = 1
const MINIMUM_LOWERCASE_LETTER_COUNT = 1
const MINIMUM_NUMBER_COUNT = 1

function PasswordHints (props: { passwordValue?: string }) {
  const { t } = useLocalization()

  const {
    minimumLength,
    containsUpperCase,
    containsLowerCase,
    containsNumber,
  } = passwordPolicy()

  const hints = [
    {
      key: 'minLength',
      validation: (value?: string) => minimumLength(value, MINIMUM_PASSWORD_LENGTH),
      message: t('security.passwordChangeForm.passwordInput.hint.minLength', {
        minLength: MINIMUM_PASSWORD_LENGTH.toString(),
      }),
    },
    {
      key: 'containsLower',
      validation: (value?: string) => containsLowerCase(value),
      message: t('security.passwordChangeForm.passwordInput.hint.lowerCase', {
        count: MINIMUM_LOWERCASE_LETTER_COUNT.toString(),
      }),
    },
    {
      key: 'containsUpper',
      validation: (value?: string) => containsUpperCase(value),
      message: t('security.passwordChangeForm.passwordInput.hint.upperCase', {
        count: MINIMUM_UPPERCASE_LETTER_COUNT.toString(),
      }),
    },
    {
      key: 'containsNumber',
      validation: (value?: string) => containsNumber(value),
      message: t('security.passwordChangeForm.passwordInput.hint.number', {
        count: MINIMUM_NUMBER_COUNT.toString(),
      }),
    },
  ]

  return (
    <List
      skin='checkmark'
      className='password-hints'
      data-testid='password-hints'
    >
      {hints.map((hint) => (
        <ListItem
          data-testid={`password-hint-${hint.key}`}
          key={hint.key}
          className={hint.validation(props.passwordValue) ? 'valid' : 'invalid'}
        >
          <Typography fontSize='standard'>{hint.message}</Typography>
        </ListItem>
      ))}
    </List>
  )
}

export default PasswordHints
