import DisplayAlert from '../../../../common/model/displayAlert'
import { TabIds } from '../../../../common/model/tabIds'
import SectionGroup, { SectionGroupChild } from '../Shared/SectionGroup'
import ConsentManager from './ConsentManager'
import ContactPreferences from './ContactPreferences'
import { isConsentRequired } from '@vp/consent-manager'
import { useUserContext } from '@vp/ubik-context'

type PreferencesProps = {
  email: string;
  onHandleShowAlert: DisplayAlert;
  resetSections: boolean;
  parentTabId: TabIds;
}

const Preferences = (props: PreferencesProps) => {
  const { locale } = useUserContext()

  const sections: SectionGroupChild[] = [
    (onToggle, isOpen) => (
      <ContactPreferences
        userEmail={props.email}
        onDisplayAlert={props.onHandleShowAlert}
        onToggle={onToggle}
        isOpen={isOpen}
      />
    ),
  ]
  if (isConsentRequired(locale)) {
    sections.push((onToggle, isOpen) => (
      <ConsentManager
        onToggle={onToggle}
        isOpen={isOpen}
        // eslint-disable-next-line react/jsx-handler-names
        displayAlert={props.onHandleShowAlert}
      />
    ))
  }

  return (
    <>
      <SectionGroup
        resetSections={props.resetSections}
        parentTabId={props.parentTabId}
      >
        {sections}
      </SectionGroup>
    </>
  )
}

export default Preferences
