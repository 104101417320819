import { useEffect, useState } from 'react'
import MyAccountPages from '../common/model/myAccountPages'
import getVistaUrl from '../infrastructure/data/vistaUrl.proxy'
import { useUserContext } from '@vp/ubik-context'

const useUrl = (page: MyAccountPages) => {
  const [url, setUrl] = useState<string>('')

  const { tenant, locale } = useUserContext()

  useEffect(() => {
    let isMounted = true

    const getMyAccountUrl = async () => {
      const hubUrl = await getVistaUrl(page, tenant || 'vistaprint', locale)
      if (isMounted) setUrl(hubUrl)
    }
    getMyAccountUrl()

    return () => {
      isMounted = false
    }
  }, [page, tenant, locale])

  return url
}

export default useUrl
