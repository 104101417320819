import { useState } from 'react'
import { Box } from '@vp/swan'
import type { AlertProps } from './Shared/Alert'
import Alert from './Shared/Alert'
import { TabContextProvider } from './TabContext'
import SettingsTabs from './SettingsTabs'
import DisplayAlert from '../../../common/model/displayAlert'
import { trackAlertEvent } from '../../../common/services/tracking'

import TrackingEventLabels from '../../../common/model/trackingEventLabels'

function AccountSettings () {
  const [alert, setAlert] = useState<AlertProps>({
    content: '',
    alertType: 'positive',
    display: false,
  })
  const dismissAlert = () => {
    setAlert({ ...alert, display: false })
  }

  const showAlert: DisplayAlert = (
    content: string,
    isSuccess?: boolean,
    action?: TrackingEventLabels
  ) => {
    setAlert({
      content,
      alertType: isSuccess ? 'positive' : 'error',
      display: true,
    })
    if (action) {
      trackAlertEvent(action, content, isSuccess !== false)
    }
  }

  const onTabChanged = () => {
    dismissAlert()
  }

  return (
    <TabContextProvider>
      <SettingsTabs
        showAlert={showAlert}
        onTabChanged={onTabChanged}
      />

      <Box marginTop='7'>
        <Alert {...alert} onDismissed={dismissAlert} />
      </Box>
    </TabContextProvider>
  )
}

export default AccountSettings
