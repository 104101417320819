/* er eslint-disable react/prop-types */
import { useContext, useState } from 'react'
import { Column, Link, Row, Typography } from '@vp/swan'

import UpdateEmailForm from './UpdateEmailForm'
import DisplayAlert from '../../../../../common/model/displayAlert'
import Section from '../../Shared/Section'
import TrackingEventLabels from '../../../../../common/model/trackingEventLabels'
import { trackEvent } from '../../../../../common/services/tracking'
import { ProfileContext } from '../../../../../contexts/ProfileContext'
import { useLogger, useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../../../../../hooks/useLocalizations'
import { getCultureFromLocale } from '../../../../../common/services/EnvironmentHelpers'

type UserEmailProps = {
  displayAlert: DisplayAlert;
  containsSocialIdentities: boolean;
  onToggle: () => void;
  isOpen: boolean;
}

const UserEmail = (props: UserEmailProps) => {
  const { t } = useLocalization()
  const userContext = useUserContext()

  const { profile, updateProfile, sendVerificationEmail } = useContext(ProfileContext)

  const [savingEmail, setSavingEmail] = useState(false)

  const { error } = useLogger()

  const onEmailSave = async (newEmail: string) => {
    trackEvent(TrackingEventLabels.SAVE_EMAIL_CLICKED)

    setSavingEmail(true)
    const { serviceError, emailAlreadyPresent } = await updateProfile({
      email: newEmail,
      language: getCultureFromLocale(userContext.locale),
    })

    let message = t('personalInformation.updateEmailMessage.success', {
      newEmail,
    })

    if (serviceError) {
      message = t('personalInformation.updateEmailMessage.error')
      error('Email update error', serviceError)
    }
    if (emailAlreadyPresent) {
      message = t('personalInformation.updateEmailMessage.conflict', {
        newEmail,
      })
    }
    setSavingEmail(false)
    props.onToggle()
    props.displayAlert(
      message,
      !serviceError && !emailAlreadyPresent,
      TrackingEventLabels.EDIT_EMAIL
    )
  }

  const verifyEmail = async () => {
    const success = await sendVerificationEmail()

    if (success) {
      props.displayAlert(
        t('personalInformation.updateEmailMessage.success', {
          newEmail: profile.email,
        }),
        true
      )
    } else {
      props.displayAlert(t('personalInformation.updateEmailMessage.error'))
      error(`Error sending Verification Email to ${profile.email}`, {
        email: profile.email,
      })
    }
  }

  return (
    <Section
      data-testid='update-email-link'
      label={t('personalInformation.email')}
      canToggle={!props.containsSocialIdentities && !profile.readOnlyMode}
      onToggle={props.onToggle}
      trackingLabel={TrackingEventLabels.EDIT_EMAIL_CLICKED}
      isOpen={props.isOpen}
      toggleText={t('personalInformation.edit')}
    >
      {{
        whenOpened: (onHide) => (
          <UpdateEmailForm
            email={profile.email}
            hide={onHide}
            onSave={onEmailSave}
            savingEmail={savingEmail}
          />
        ),

        whenClosed: (
          <>
            <Row>
              <Column span={8} spanXs={12}>
                <div style={{ width: '50%', float: 'left' }}>
                  <Typography
                    data-test-name='customer-email'
                  >
                    {profile.email}
                  </Typography>
                </div>
              </Column>
              {profile.isEmailVerified === false &&
                !props.containsSocialIdentities && (
                  <Column span={4} spanXs={12} className='verify-email'>
                    <Link
                      component='button'
                      className='link-text'
                      onClick={verifyEmail}
                    >
                      <Typography fontSize='xsmall'>
                        {t('personalInformation.verifyEmail')}
                      </Typography>
                    </Link>
                  </Column>
              )}
            </Row>
            {props.containsSocialIdentities && (
              <Row marginTop={3}>
                <Column span={6} spanSm={8} spanXs={9}>
                  <Typography fontSize='small' textColor='subtle'>
                    {t('personalInformation.emailChangeNotification')}
                  </Typography>
                </Column>
              </Row>
            )}
          </>
        ),
      }}
    </Section>
  )
}

export default UserEmail
