import React from 'react'
import { Translations } from '../../../../translations'
import { useLocalization } from '../../../../hooks/useLocalizations'

const parseTransString = (text: string, components: React.ReactNode[]) => {
  const parts = text.split(/(<\d+>.*?<\/\d+>)/g)

  return parts.map((part, i) => {
    const match = part.match(/^<(\d+)>(.*?)<\/\1>$/)
    if (match) {
      const index = parseInt(match[1], 10)
      return components[index] ? React.cloneElement(components[index] as React.ReactElement, {}, match[2]) : match[2]
    }
    return part
  })
}

export const Trans: React.FC<{ resourceKey: Translations; tokens: { [key: string]: string }; components?: React.ReactNode[]; }> = ({
  resourceKey,
  tokens,
  components = [],
}) => {
  const { t } = useLocalization()
  const translatedText = t(resourceKey, tokens)
  return <>{parseTransString(translatedText, components)}</>
}
