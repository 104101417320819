import { SocialIdentity } from '../model/profile'

const SOCIAL_ACCOUNTS = {
  google: 'Google',
  facebook: 'Facebook',
  apple: 'Apple',
}

const filterAuthZeroFromIdentities = (identityProviders: SocialIdentity[]) => {
  return (identityProviders || [])
    .filter((identity: SocialIdentity) => SOCIAL_ACCOUNTS[identity])
    .map((identity) => SOCIAL_ACCOUNTS[identity])
}

export default filterAuthZeroFromIdentities
