import {
  Spinner,
  Box,
  Button,
  FlexBox,
  FormField,
  FormHelper,
  FormInputGroup,
  Link,
  StandardForm,
} from '@vp/swan'
import React, { SyntheticEvent, useState } from 'react'

import passwordPolicy from './passwordPolicy'
import { useUpdatePassword } from '../../../../../hooks/useUpdatePassword'
import PasswordHints from './PasswordHints'
import PasswordInput from './PasswordInput'
import { useLocalization } from '../../../../../hooks/useLocalizations'

const MINIMUM_PASSWORD_LENGTH = 8

type PasswordChangeEvent = {
  eventType: 'passwordChangeSuccess' | 'passwordChangeFailure' | 'hidePasswordFields' | 'passwordSectionChanged';
  message?: string | boolean;
}

type PasswordFormProps = {
  onEmitEvent: (event: PasswordChangeEvent) => void;
}

function PasswordForm (props: PasswordFormProps) {
  const { t } = useLocalization()
  const [changingPassword, setChangingPassword] = useState(false)
  const [newPassword, setNewPassword] = useState({
    value: '',
    isValid: false,
    inFocus: true,
  })
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const { updatePassword } = useUpdatePassword()

  const { isFair, minimumLength } = passwordPolicy()

  const cleanPasswords = () => {
    changePassword('')
    setConfirmedPassword('')
  }

  const getFailureReason = (error: any) =>
    error.type === 'weakPassword'
      ? t('security.passwordChangeForm.notification.error.weakPassword')
      : t('security.passwordChangeForm.notification.error.generic')

  const onCancel = (event: SyntheticEvent) => {
    cleanPasswords()
    event.preventDefault()
    props.onEmitEvent({ eventType: 'hidePasswordFields' })
  }

  const onSave = async (event: SyntheticEvent) => {
    event.preventDefault()
    if (!newPassword.isValid) {
      return
    }

    try {
      setChangingPassword(true)
      const response = await updatePassword(newPassword.value)
      if (response.status === 200) {
        props.onEmitEvent({ eventType: 'passwordChangeSuccess', message: t('security.passwordChangeForm.notification.success') })
      } else {
        props.onEmitEvent({ eventType: 'passwordChangeFailure', message: t('security.passwordChangeForm.notification.error.generic') })
      }
    } catch (error) {
      const message = getFailureReason(error)
      props.onEmitEvent({ eventType: 'passwordChangeFailure', message })
    }
    setChangingPassword(false)
    props.onEmitEvent({ eventType: 'hidePasswordFields' })
  }

  const isValidPassword = (password: string): boolean =>
    isFair(password) && minimumLength(password, MINIMUM_PASSWORD_LENGTH)

  const changePassword = (newPassword: string) => {
    setNewPassword({
      value: newPassword,
      isValid: isValidPassword(newPassword),
      inFocus: true,
    })
    props.onEmitEvent({ eventType: 'passwordSectionChanged', message: !!newPassword })
  }

  const canSavePassword = () =>
    newPassword.isValid &&
    newPassword.value === confirmedPassword &&
    !changingPassword

  return (
    <StandardForm className='change-password-form' noValidate>
      <FormField>
        <FormInputGroup className='swan-input-with-button-inset'>
          <PasswordInput
            label={t('security.passwordChangeForm.passwordInput.label')}
            data-testid='textinput-passwd'
            value={newPassword.value}
            onChange={(event: { target: { value: any } }) => changePassword(event.target.value)}
            onBlur={() =>
              setNewPassword({ ...newPassword, inFocus: false })}
            validator={() => newPassword.inFocus || newPassword.isValid}
            errorText=''
          />
        </FormInputGroup>
        <FormInputGroup className='swan-input-with-button-inset'>
          <FormHelper component='div'>
            <PasswordHints passwordValue={newPassword.value} />
          </FormHelper>

        </FormInputGroup>
        <FormInputGroup className='swan-input-with-button-inset'>
          <PasswordInput
            label={t('security.passwordChangeForm.passwordInput.confirmPassword')}
            data-testid='textinput-confirm-passwd'
            value={confirmedPassword}
            onChange={(event: { target: { value: any } }) => setConfirmedPassword(event.target.value)}
            validator={() => newPassword.value === confirmedPassword}
            errorText={t('security.passwordChangeForm.passwordInput.errorConfirmPassword')}
          />
        </FormInputGroup>
      </FormField>
      <FlexBox
        alignItems='center'
        className='password-buttons'
        data-testid='password-buttons'
      >
        <Box className='save-btn' mr='between-actions'>
          <Button
            className='change-password-button'
            data-testid='change-password-button'
            skin='primary'
            disabled={!canSavePassword()}
            onClick={onSave}
          >
            {changingPassword && (
              <Spinner accessibleText={t('loading')} size='tiny' />
            )}
            {t('security.passwordChangeForm.submit')}
          </Button>
        </Box>
        <Link
          type='button'
          className='cancel-btn'
          component='button'
          onClick={onCancel}
        >
          {t('security.passwordChangeForm.cancel')}
        </Link>
      </FlexBox>
    </StandardForm>
  )
}

export default PasswordForm
