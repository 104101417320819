import {
  TextInputWithFloatingLabel,
  TextInput,
  TextInputFloatingLabel,
  FormField,
  FormError,
  FormInputGroup,
} from '@vp/swan'

type FormTextBoxProps = {
  'data-testid'?: string;
  label: string;
  value: string;
  onChange: (event: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  validator?: (text: string) => boolean;
  errorText?: string;
}

function FormTextBox (props: FormTextBoxProps) {
  const { label, value, onChange, onBlur, onFocus, validator, errorText } =
    props

  return (
    <FormField>
      <FormInputGroup>
        <TextInputWithFloatingLabel>
          <TextInput
            data-testid={props['data-testid']}
            type='text'
            value={value}
            autoComplete='off'
            autoCapitalize='off'
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <TextInputFloatingLabel>{label}</TextInputFloatingLabel>
        </TextInputWithFloatingLabel>
        {validator && !validator(value) && <FormError>{errorText}</FormError>}
      </FormInputGroup>
    </FormField>
  )
}

export default FormTextBox
