import { getTracking } from '@vp/tracking'
import TrackingEventLabels from '../model/trackingEventLabels'

const trackEvent = (label: TrackingEventLabels, eventDetails?: any) => {
  const tracking = getTracking()

  if (!tracking) {
    console.info('Tracking is not available')
    return
  }

  tracking.track(
    'Account Settings Clicked',
    {
      route: 'My Account',
      category: 'My Account',
      label,
      eventDetails,
    },
    {}
  )
}

const trackAlertEvent = (
  label: TrackingEventLabels,
  alertMessage: string,
  isSuccess: boolean
) => {
  const tracking = getTracking()

  if (!tracking) {
    console.info('Tracking is not available')
    return
  }

  tracking.track(
    'Account Settings Clicked',
    {
      route: 'My Account',
      category: 'My Account',
      label: isSuccess ? 'Success' : 'Error',
      eventDetails: alertMessage,
    },
    {}
  )
}

const AccountDeletionReasonValues = [
  'notProvided',
  'unhappy',
  'duplicated',
  'tooMuchMarketing',
  'noLongerNeed',
  'deletePersonalData',
  'other',
] as const

export type AccountDeletionReason =
  (typeof AccountDeletionReasonValues)[number]

function trackAccountDeletionRequest (
  label: TrackingEventLabels,
  deleteAccountReason: AccountDeletionReason,
  requestStatus: string
) {
  const eventDetails = `Delete Account Reason: ${deleteAccountReason}, Status: ${requestStatus}`
  trackEvent(label, eventDetails)
}

export {
  trackEvent,
  trackAlertEvent,
  trackAccountDeletionRequest,
  AccountDeletionReasonValues,
}
