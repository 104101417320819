import { useEffect, useState } from 'react'
import getCareTeamUrl from '../infrastructure/data/careTeamUrl'
import { useUserContext } from '@vp/ubik-context'

const useCareTeamUrl = (page: string) => {
  const [url, setUrl] = useState<string>('')

  const { tenant, locale } = useUserContext()

  useEffect(() => {
    const getCustomerCareTeamUrl = async () => {
      const customerCareTeamUrl = await getCareTeamUrl(page, tenant || 'vistaprint', locale)
      setUrl(customerCareTeamUrl)
    }
    getCustomerCareTeamUrl()
  }, [page, tenant, locale])

  return url
}

export default useCareTeamUrl
