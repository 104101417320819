import { useContext } from 'react'

import { Card, Column, Link, Row, Typography } from '@vp/swan'
import DisplayAlert from '../../../../common/model/displayAlert'
import MyAccountPages from '../../../../common/model/myAccountPages'
import Section from '../Shared/Section'
import { TabContext } from '../TabContext'
import useUrl from '../../../../hooks/useUrl'
import TrackingEventLabels from '../../../../common/model/trackingEventLabels'
import { trackEvent } from '../../../../common/services/tracking'
import { useLocalization } from '../../../../hooks/useLocalizations'
import { EmailPreference } from '../ContactPreferences/EmailPreferencesSwitch'
import ContactPreferencesUpdater from '../ContactPreferences/ContactPreferencesUpdater'
import { Trans } from '../Shared/Trans'
import useStoredPreferences from '../../../../hooks/useStoredPreferences'

const POLLING_INTERVAL_MS = 5000

type ContactPreferencesProps = {
  userEmail: string;
  onDisplayAlert: DisplayAlert;
  onToggle: () => void;
  isOpen: boolean;
}

const ContactPreferences = (props: ContactPreferencesProps) => {
  const { setSectionAltered } = useContext(TabContext)
  const privacyUrl = useUrl(MyAccountPages.PrivacyAndCookiePolicy)
  const { t } = useLocalization()
  const { initialPreference, setInitialPreference } = useStoredPreferences(props.userEmail, POLLING_INTERVAL_MS)

  const PrivacyPolicyLink = () => (
    <Typography mt='5' mb='7'>
      <Trans
        resourceKey='contactPreferences.privacyPolicy'
        tokens={{
          privacyAndCookiePolicyUrl: t('contactPreferences.privacyPolicyLink'),
        }}
        components={[
          <Link data-testid='invalid-document-help-button' href={privacyUrl} key='privacyAndCookiePolicyUrl'>
            privacyAndCookiePolicyUrl
          </Link>
        ]}
      />
    </Typography>
  )

  return (
    <>
      <Section
        data-testid='update-contact-preferences'
        label={t('contactPreferences.title')}
        canToggle
        onToggle={props.onToggle}
        trackingLabel={TrackingEventLabels.EDIT_CONTACT_PREFERENCES_CLICKED}
        isOpen={props.isOpen}
        forceRerenderToggle={initialPreference}
        toggleText={t('contactPreferences.manage')}
      >
        {{
          whenOpened: (onClose) => (
            <>
              <Typography mt='5'>
                {t('contactPreferences.updateAnyTime')}
              </Typography>
              <PrivacyPolicyLink />
              <Card paddingY='6' paddingX='7' backgroundColor='strong'>
                <ContactPreferencesUpdater
                  userEmail={props.userEmail}
                  onCancel={() => {
                    onClose()
                    setSectionAltered(false)
                  }}
                  onSaveClicked={(
                    oldPreference: EmailPreference,
                    newPreference: EmailPreference
                  ) => {
                    trackEvent(
                      TrackingEventLabels.SAVE_CONTACT_PREFERENCES_CLICKED,
                      newPreference
                    )
                    setInitialPreference(newPreference)
                  }}
                  onAltered={setSectionAltered}
                  onSendFeedback={(content: string, isSuccess: boolean | undefined) => {
                    props.onDisplayAlert(
                      content,
                      isSuccess,
                      TrackingEventLabels.EDIT_CONTACT_PREFERENCES
                    )
                    setSectionAltered(false)
                    onClose()
                  }}
                />
              </Card>
            </>
          ),
          whenClosed:
  <>
    <Row>
      <Column span={8} spanXs={12}>
        <Typography
          data-test-name='customer-email'
        >
          {t(`contactPreferences.${initialPreference}`)}
        </Typography>
      </Column>
    </Row>
  </>,
        }}
      </Section>
    </>
  )
}

export default ContactPreferences
