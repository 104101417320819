import { Column, Link, Row, StyleTextColor, Typography } from '@vp/swan'
import { JSX, useContext, useMemo } from 'react'
import TrackingEventLabels from '../../../../common/model/trackingEventLabels'
import { trackEvent } from '../../../../common/services/tracking'
import { ProfileContext } from '../../../../contexts/ProfileContext'

type SectionProps = {
  'data-testid'?: string;
  label: string;
  labelTextColor?: StyleTextColor;
  canToggle: boolean;
  isOpen: boolean;
  onToggle: () => void;
  trackingLabel: TrackingEventLabels;
  toggleText: string;
  forceRerenderToggle?: string; // Added to ensure that the component fails useMemo hook and rerenders due to outside changes
  children: {
    whenOpened: (onClose: () => void) => JSX.Element;
    whenClosed: JSX.Element;
  };
}

function Section (props: SectionProps) {
  const { label, canToggle, isOpen, onToggle, toggleText, labelTextColor } =
    props
  const { profile } = useContext(ProfileContext)
  const rerenderToggleDependency = props.forceRerenderToggle ?? ''

  const toggle = () => {
    if (!isOpen) trackEvent(props.trackingLabel)
    onToggle()
  }

  const opened = useMemo(
    () => props.children.whenOpened(toggle),
    [isOpen, profile, rerenderToggleDependency]
  )
  const closed = useMemo(() => props.children.whenClosed, [isOpen, profile, rerenderToggleDependency])

  return (
    <>
      <Row>
        <Column span={7} spanXs={7}>
          <Typography
            fontSkin='title-subsection'
            textAlign='left'
            textColor={labelTextColor ?? 'standard'}
            mb='between-actions'
          >
            {label}
          </Typography>
        </Column>
        <Column className='text-right' span={5} spanXs={5}>
          {canToggle && (
            <Link
              data-testid={props['data-testid']}
              component='button'
              className='link-text'
              onClick={toggle}
            >
              <Typography textAlign='right'>{!isOpen && toggleText}</Typography>
            </Link>
          )}
        </Column>
      </Row>
      <Row>
        <Column span={12}>{isOpen ? opened : closed}</Column>
      </Row>
    </>
  )
}

export default Section
