import { AlertBox, Typography, AlertBoxDismissButton } from '@vp/swan'
import { AlertType } from '../../../../domain/model/alertType'

type AlertProps = {
  content: string;
  alertType: AlertType;
  display: boolean;
  onDismissed?: () => void;
}

function Alert (props: AlertProps) {
  const { content, alertType, display, onDismissed } = props

  if (!display) return null

  setTimeout(onDismissed as any, 2000)

  return (
    <AlertBox
      data-testid='alert'
      skin={alertType}
      dismissed={!display}
      onRequestDismiss={onDismissed}
    >
      <Typography fontWeight='bold'>{content}</Typography>
      <AlertBoxDismissButton visuallyHiddenLabel='Dismiss alert' />
    </AlertBox>
  )
}

export type { AlertType, AlertProps }
export default Alert
