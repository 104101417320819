import { Column, GridContainer, Row } from '@vp/swan'
import UserName from './Name/UserName'
import UserEmail from './Email/UserEmail'
import Divider from '../Shared/Divider'
import DisplayAlert from '../../../../common/model/displayAlert'
import GoToPayments from './GoToPayments'
import SectionGroup from '../Shared/SectionGroup'
import { useContext } from 'react'
import SocialLoginSection from './SocialLoginSection'
import filterAuthZeroFromIdentities from '../../../../common/services/socialIdentityFilter'
import { TabIds } from '../../../../common/model/tabIds'
import { ProfileContext } from '../../../../contexts/ProfileContext'

type PersonalInfoProps = {
  displayAlert: DisplayAlert;
  resetSections: boolean;
  parentTabId: TabIds;
}

function PersonalInfo (props: PersonalInfoProps) {
  const { displayAlert } = props

  const { profile } = useContext(ProfileContext)
  const { identityProviders } = profile

  const socialLogins = filterAuthZeroFromIdentities(identityProviders).map(
    (identity) => (onToggle: () => void, isOpen: boolean) =>
      (
        <SocialLoginSection
          canManagePassword={profile.canManagePassword}
          displayAlert={props.displayAlert}
          onToggle={onToggle}
          isOpen={isOpen}
          primaryIdentity={identity}
        />
      )
  )

  return (
    <>
      <SectionGroup
        resetSections={props.resetSections}
        parentTabId={props.parentTabId}
      >
        {[
          (onToggle, isOpen) => (
            <UserName
              displayAlert={displayAlert}
              onToggle={onToggle}
              isOpen={isOpen}
            />
          ),
          (onToggle, isOpen) => (
            <UserEmail
              containsSocialIdentities={!!socialLogins.length}
              displayAlert={displayAlert}
              onToggle={onToggle}
              isOpen={isOpen}
            />
          ),
          ...socialLogins,
        ]}
      </SectionGroup>
      <GridContainer>
        <Row mt='between-actions'>
          <Column span={6}>
            <Divider />
            <GoToPayments />
          </Column>
        </Row>
      </GridContainer>
    </>
  )
}

export default PersonalInfo
