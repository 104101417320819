import { TabIds } from '../model/tabIds'

const TAB_PARAM_NAME = 't'
const SECTION_PARAM_NAME = 's'
const DEFAULT_TAB = TabIds.PersonalInfo
const DEFAULT_SECTION = -1

type QueryStringParams = {
  [key: string]: string | undefined;
} | undefined

const getInitialTab = (queryStringParams: QueryStringParams) => getTabIdFromQueryParams(queryStringParams) ?? DEFAULT_TAB

const getInitialSection = (parentTabId: TabIds, queryStringParams: QueryStringParams) => {
  if (!getTabIdFromQueryParams(queryStringParams)) return DEFAULT_SECTION
  if (getInitialTab(queryStringParams) !== parentTabId) return DEFAULT_SECTION

  return getSectionIdFromQueryParams(parentTabId, queryStringParams) ?? DEFAULT_SECTION
}

const getTabIdFromQueryParams = (queryStringParams: QueryStringParams) => {
  const tabFromParam = getParam(TAB_PARAM_NAME, queryStringParams)

  if (!tabFromParam || !isValidTab(tabFromParam)) return null

  return tabFromParam as TabIds
}

const getSectionIdFromQueryParams = (parentTabId: TabIds, queryStringParams: QueryStringParams) => {
  const sectionFromParam = getParam(SECTION_PARAM_NAME, queryStringParams)

  if (!sectionFromParam || !isValidSection(parentTabId, sectionFromParam)) { return null }

  return sectionTranslator[parentTabId].sections[sectionFromParam]
}

const getParam = (paramName: string, queryStringParams: QueryStringParams) => {
  if (queryStringParams && queryStringParams[paramName]) {
    return queryStringParams[paramName]
  }
  if (typeof window === 'undefined') { return null }
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get(paramName)
}

const isValidTab = (tabId: string) => {
  return Object.values(TabIds).includes(tabId as any)
}

const isValidSection = (tabId: TabIds, sectionId: string) => {
  return Object.keys(sectionTranslator[tabId].sections).includes(sectionId)
}

type SectionTranslator = {
  [key in TabIds]: {
    sections: {
      [key in string]: number;
    };
  };
}

const sectionTranslator: SectionTranslator = {
  pi: {
    sections: { nm: 0, em: 1 },
  },
  se: {
    sections: { pw: 0 },
  },
  ps: {
    sections: { cp: 0, cs: 1 },
  },
}

export { TAB_PARAM_NAME, SECTION_PARAM_NAME, getInitialTab, getInitialSection }
