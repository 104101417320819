import { useState, useContext } from 'react'

import {
  TabsHeaders,
  Tabs,
  TabHeader,
  TabContent,
  TabsContents,
} from '@vp/swan'
import Security from './SecurityTab/Security'
import PersonalInfo from './PersonalInfoTab/PersonalInfo'
import { TabContext } from './TabContext'
import DisplayAlert from '../../../common/model/displayAlert'
import { trackEvent } from '../../../common/services/tracking'

import Preferences from './PreferencesTab/Preferences'
import TrackingEventLabels from '../../../common/model/trackingEventLabels'
import { getInitialTab } from '../../../common/services/queryParamsService'
import { TabIds } from '../../../common/model/tabIds'
import { useLocalization } from '../../../hooks/useLocalizations'
import { useUserContext } from '@vp/ubik-context'
import { ProfileContext } from '../../../contexts/ProfileContext'

type SettingsTabsProps = {
  showAlert: DisplayAlert;
  onTabChanged: () => void;
}

function SettingsTabs (props: SettingsTabsProps) {
  const { showAlert } = props
  const { t } = useLocalization()

  const { tryChange } = useContext(TabContext)
  const { profile } = useContext(ProfileContext)

  const { queryStrings } = useUserContext()

  const DEFAULT_TAB = getInitialTab(queryStrings)

  const [refreshSections, setRefreshSections] = useState(false)
  const [selectedTabId, setSelectedTabId] = useState<string | number>(
    DEFAULT_TAB
  )

  const changeToNewTab = (tabId: TabIds | null) => {
    switch (tabId) {
      case TabIds.PersonalInfo:
        trackEvent(TrackingEventLabels.MY_ACCOUNT_SETTINGS_PERSONAL_INFO_TAB)
        break
      case TabIds.Security:
        trackEvent(TrackingEventLabels.MY_ACCOUNT_SETTINGS_SECURITY_TAB)
        break
      case TabIds.Preferences:
        trackEvent(TrackingEventLabels.MY_ACCOUNT_SETTINGS_PREFERENCES_TAB)
        break
    }

    setSelectedTabId(tabId || DEFAULT_TAB)
    props.onTabChanged()
    setRefreshSections(!refreshSections)
  }

  const canManagePassword = profile?.canManagePassword || false

  return (
    <Tabs
      selectedTabId={selectedTabId?.toString()}
      onRequestTabChange={(tabId: string | null) =>
        tryChange(() => changeToNewTab(tabId as TabIds))}
    >
      <TabsHeaders>
        <TabHeader tabId={TabIds.PersonalInfo} data-testid='personal-info-tab'>
          {t('personalInformation.title')}
        </TabHeader>
        <TabHeader tabId={TabIds.Security} data-testid='security-tab'>
          {t('security.title')}
        </TabHeader>
        <TabHeader tabId={TabIds.Preferences}>
          {t('consentManager.title')}
        </TabHeader>
      </TabsHeaders>
      <TabsContents>
        <TabContent
          tabId={TabIds.PersonalInfo}
          data-testid='personal-info-content'
        >
          <PersonalInfo
            resetSections={refreshSections}
            displayAlert={showAlert}
            parentTabId={TabIds.PersonalInfo}
          />
        </TabContent>
        <TabContent tabId={TabIds.Security} data-testid='password-content'>
          <Security
            resetSections={refreshSections}
            displayAlert={showAlert}
            parentTabId={TabIds.Security}
            canManagePassword={canManagePassword}
            canDeleteAccount
          />
        </TabContent>
        <TabContent
          tabId={TabIds.Preferences}
          data-testid='preferences-content'
        >
          <Preferences
            resetSections={refreshSections}
            email={profile?.email || ''}
            onHandleShowAlert={showAlert}
            parentTabId={TabIds.Preferences}
          />
        </TabContent>
      </TabsContents>
    </Tabs>
  )
}

export default SettingsTabs
