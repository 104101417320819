import React, { useMemo } from 'react'
import useProfile, { BLANK_PROFILE, ProfileType, UseProfileType } from '../hooks/useProfile'

export const ProfileContext = React.createContext<UseProfileType>({
  isReady: false,
  profile: BLANK_PROFILE,
  profileError: '',
  updateProfile: async (profile: Partial<ProfileType>) => ({
    serviceError: null,
    emailAlreadyPresent: false,
  }),
  sendVerificationEmail: async () => false,
  sendAccountDeletionRequest: async () => ({ success: false }),
})

export const ProfileContextProvider = (props: { children: any }) => {
  const { children } = props
  const { isReady, profile, profileError, updateProfile, sendVerificationEmail, sendAccountDeletionRequest } =
    useProfile()

  const value = useMemo(
    () => ({
      isReady,
      profile,
      profileError,
      updateProfile,
      sendVerificationEmail,
      sendAccountDeletionRequest,
    }),
    [isReady, profile, profileError, sendVerificationEmail, updateProfile, sendAccountDeletionRequest]
  )

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}
