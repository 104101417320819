import { useState, useRef, useContext } from 'react'
import { Box, Row, Link, Column, Typography, Button } from '@vp/swan'
import DisplayAlert from '../../../../../common/model/displayAlert'

import TrackingEventLabels from '../../../../../common/model/trackingEventLabels'
import { trackEvent } from '../../../../../common/services/tracking'
import { useLocalization } from '../../../../../hooks/useLocalizations'
import { ProfileContext } from '../../../../../contexts/ProfileContext'
import { Trans } from '../../Shared/Trans'

type DisconnectSocialIdentityProps = {
  socialIdpToBeDisconnected: string;
  displayAlert: DisplayAlert;
  onClose: () => void;
}

function capitalizeFirstLetter (idp = '') {
  return idp.charAt(0).toUpperCase() + idp.slice(1)
}

const DisconnectSocialIdentity = (props: DisconnectSocialIdentityProps) => {
  const { t } = useLocalization()
  const { profile, updateProfile } = useContext(ProfileContext)

  const { identityProviders = [] } = profile || {}
  const isPrimaryIdentityDisconnected = identityProviders.length === 1

  const [disconnectIdp, setDisconnectIdp] = useState({
    isIdpDisconnected: false,
    isDisconnectingIdp: false,
  })
  const refUserProfile = useRef(profile)

  const onDisconnect = async () => {
    trackEvent(TrackingEventLabels.SAVE_DISCONNECT_SOCIAL_ACCOUNT_CLICKED)

    setDisconnectIdp({ isIdpDisconnected: false, isDisconnectingIdp: true })
    const data = await updateProfile({
      identityProviders: {
        remove: [props.socialIdpToBeDisconnected.toLowerCase()],
      } as any,
    })

    if (!data.serviceError) {
      // we are disabling any edits when a user has disconnected their primary social identifier
      refUserProfile.current = {
        ...refUserProfile.current,
        identityProviders: refUserProfile.current.identityProviders.filter(
          (x) => x !== props.socialIdpToBeDisconnected.toLowerCase()
        ),
        readOnlyMode: isPrimaryIdentityDisconnected,
      }
      setDisconnectIdp({ isIdpDisconnected: true, isDisconnectingIdp: false })
    } else {
      props.displayAlert(
        t('disconnectSocialLogin.error'),
        false,
        TrackingEventLabels.DISCONNECT_SOCIAL_ACCOUNT
      )
    }
  }

  return (
    <>
      {!disconnectIdp.isIdpDisconnected
        ? (
          <>
            <Row marginTop='3'>
              <Column span={12} spanXs={10}>
                <Typography>
                  {t('disconnectSocialLogin.disconnectIdentity', {
                    socialIdp: capitalizeFirstLetter(
                      props.socialIdpToBeDisconnected
                    ),
                  })}
                </Typography>
              </Column>
            </Row>
            {isPrimaryIdentityDisconnected && (
              <Row marginTop='3'>
                <Column span={12} spanXs={10}>
                  <Trans resourceKey='disconnectSocialLogin.disconnectPrimaryIdentity' tokens={{ email: profile.email }} components={[<b key={1} />]} />
                </Column>
              </Row>
            )}
            <Box paddingY='5'>
              <Button
                data-testid='disconnect-social-identity-button'
                mr='between-actions'
                skin='primary'
                disabled={disconnectIdp.isDisconnectingIdp}
                onClick={onDisconnect}
              >
                {t('disconnectSocialLogin.disconnectButton', {
                  socialIdp: capitalizeFirstLetter(
                    props.socialIdpToBeDisconnected
                  ),
                })}
              </Button>
              <Link
                component='button'
                className='link-text'
                onClick={(e: any) => {
                  e.preventDefault()
                  props.onClose()
                }}
              >
                <Typography textAlign='center'>
                  {t('personalInformation.cancel')}
                </Typography>
              </Link>
            </Box>
          </>
          )
        : (
          <>
            <Row marginTop='7' data-testid='disconnect-social-identity-success'>
              <Column span={12}>
                <Typography fontSize='x2large'>
                  {t(
                    'disconnectSocialLogin.disconnectedIdp.successDisconnectMessage'
                  )}
                </Typography>
              </Column>
            </Row>
            {isPrimaryIdentityDisconnected && (
              <Row marginTop='7'>
                <Column span={12} spanXs={10}>
                  <Trans resourceKey='disconnectSocialLogin.disconnectedIdp.successDisconnectPrimaryIdentity' tokens={{ email: profile.email }} components={[<b key={1} />]} />
                </Column>
              </Row>
            )}
            <Box paddingBottom={9}>
              <Row marginTop='7' marginBottom={9}>
                <Column span={6} spanXs={12} push={3}>
                  <Button
                    data-testid='back-to-account-settings-button'
                    skin='primary'
                  >
                    {t('disconnectSocialLogin.disconnectedIdp.thankyou')}
                  </Button>
                </Column>
              </Row>
            </Box>
          </>
          )}
    </>
  )
}

export default DisconnectSocialIdentity
