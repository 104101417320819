import {
  AlertBox,
  ModalDialog,
  ModalDialogContent,
  ModalDialogNav,
  ModalDialogCloseButton,
  ModalDialogHeader,
  ModalDialogTitle,
  ModalDialogBody,
  Typography,
  Ul,
  Li,
  ModalDialogFooter,
  Button,
  Link,
  Spinner,
  Dropdown,
  DropdownOption,
} from '@vp/swan'
import { useState } from 'react'
import {
  AccountDeletionReasonValues,
  AccountDeletionReason,
} from '../../../../../common/services/tracking'
import { useLocalization } from '../../../../../hooks/useLocalizations'

type AccountDeletionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (selectedReason: AccountDeletionReason) => void;
  hasError: boolean;
  isSubmitting: boolean;
}

export const AccountDeletionModal = ({
  isOpen,
  onClose,
  onSubmit,
  hasError,
  isSubmitting,
}: AccountDeletionModalProps) => {
  const { t } = useLocalization()
  const [selectedReason, setSelectedReason] =
    useState<AccountDeletionReason>('notProvided')

  return (
    <ModalDialog
      isOpen={isOpen}
      onRequestDismiss={onClose}
      takeOver
      onlyRenderWhenOpen
    >
      <ModalDialogContent
        aria-labelledby='modal dialog takeover'
        data-testid='account-deletion-content'
      >
        <ModalDialogNav>
          <ModalDialogCloseButton
            accessibleText={t('close')}
            disabled={isSubmitting}
            aria-disabled={isSubmitting}
          />
        </ModalDialogNav>
        <div style={{ margin: 'auto', maxWidth: '874px' }}>
          {hasError && (
            <AlertBox
              mb='4'
              skin='error'
              data-testid='delete-account-error-alert'
            >
              {t('security.deletionError')}
            </AlertBox>
          )}
          <ModalDialogHeader>
            <ModalDialogTitle mb='6'>
              {t('security.deleteAccountModal.header')}
            </ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            <Typography pb='5'>
              {t('security.deleteAccountModal.noLongerAccess')}
            </Typography>
            <Ul ml='3' pb='5'>
              <Li pb={0}>
                {t('security.deleteAccountModal.list.yourAccount')}
              </Li>
              <Li pb={0}>
                {t('security.deleteAccountModal.list.yourOrderHistory')}
              </Li>
              <Li pb={0}>
                {t('security.deleteAccountModal.list.yourSubscriptions')}
              </Li>
              <Li pb={0}>
                {t('security.deleteAccountModal.list.yourProducts')}
              </Li>
              <Li pb={0}>
                {t('security.deleteAccountModal.list.yourUploads')}
              </Li>
              <Li pb={0}>
                {t('security.deleteAccountModal.list.editOrReorder')}
              </Li>
            </Ul>
            <Typography pb='5'>
              {t('security.deleteAccountModal.pleaseNote')}
            </Typography>
            <Typography pb='5'>
              {t('security.deleteAccountModal.outstanding')}
            </Typography>
            <Typography pb='5'>
              {t('security.deleteAccountModal.deleteAccountInstructions')}
            </Typography>
            <Typography>
              {t('security.deleteAccountModal.dropdown.tellUs')}
            </Typography>
          </ModalDialogBody>
          <ModalDialogFooter>
            <Dropdown
              mb={6}
              aria-label='Reason for deletion request'
              skin='standard'
              value={selectedReason}
              onChange={(event: { target: { value: string; }; }) =>
                setSelectedReason(event.target.value as AccountDeletionReason)}
            >
              {AccountDeletionReasonValues.map((reason) => (
                <DropdownOption key={reason} value={reason}>
                  {t(`security.deleteAccountModal.dropdown.${reason}`)}
                </DropdownOption>
              ))}
            </Dropdown>
            <AlertBox skin='warning' mb={6} textAlign='left'>
              {t('security.deleteAccountModal.permanentChange')}
            </AlertBox>
            <Button
              mr='6'
              disabled={isSubmitting}
              onClick={() => onSubmit(selectedReason)}
              skin='secondary'
            >
              {isSubmitting && (
                <>
                  <Spinner accessibleText={t('submitting')} size='tiny' />
                  {t('submitting')}
                </>
              )}

              {!isSubmitting && t('security.deleteAccount')}
            </Button>
            <Link
              render={(p: { className: any; children: any }) => (
                <Button
                  skin='link'
                  className={p.className}
                  onClick={onClose}
                  disabled={isSubmitting}
                  aria-disabled={isSubmitting}
                >
                  {p.children}
                </Button>
              )}
            >
              {t('security.cancel')}
            </Link>
          </ModalDialogFooter>
        </div>
      </ModalDialogContent>
    </ModalDialog>
  )
}
