import { VPAuthConfig } from '@vp/ubik-context'

export const isLocal = process.env.IS_LOCAL === 'true'

export default (culture: string): VPAuthConfig => ({
  culture,
  developmentMode: isLocal,
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
})
