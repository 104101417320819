import { Row, Column, Typography } from '@vp/swan'
import DisplayAlert from '../../../../common/model/displayAlert'
import TrackingEventLabels from '../../../../common/model/trackingEventLabels'
import Section from '../Shared/Section'
import DisconnectSocialIdentity from './Identities/DisconnectSocialIdentity'
import { useLocalization } from '../../../../hooks/useLocalizations'

type SocialLoginsProps = {
  canManagePassword: boolean;
  displayAlert: DisplayAlert;
  onToggle: () => void;
  isOpen: boolean;
  primaryIdentity: string;
}

const SocialLoginSection = (props: SocialLoginsProps) => {
  const { t } = useLocalization()

  const whenClosed = (
    <>
      {!props.canManagePassword && (
        <Row marginTop='3'>
          <Column span={6} spanSm={8} spanXs={9}>
            <Typography
              fontSize='small'
              textColor='subtle'
              data-testid='password-notification'
            >
              {t('personalInformation.social.passwordChangeNotification', {
                identity: props.primaryIdentity,
              })}
            </Typography>
          </Column>
        </Row>
      )}
    </>
  )

  return (
    <Section
      data-testid='social-link'
      label={props.primaryIdentity}
      canToggle
      onToggle={props.onToggle}
      trackingLabel={TrackingEventLabels.EDIT_DISCONNECT_SOCIAL_ACCOUNT_CLICKED}
      isOpen={props.isOpen}
      toggleText={t('disconnectSocialLogin.disconnect')}
    >
      {{
        whenOpened: (onClose) => (
          <DisconnectSocialIdentity
            displayAlert={props.displayAlert}
            socialIdpToBeDisconnected={props.primaryIdentity}
            onClose={onClose}
          />
        ),
        whenClosed,
      }}
    </Section>
  )
}

export default SocialLoginSection
