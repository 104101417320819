import { useContext, useState } from 'react'
import { Typography } from '@vp/swan'

import UpdateInfoForm from './UpdateInfoForm'
import DisplayAlert from '../../../../../common/model/displayAlert'
import Section from '../../Shared/Section'
import TrackingEventLabels from '../../../../../common/model/trackingEventLabels'
import { trackEvent } from '../../../../../common/services/tracking'
import { useLocalization } from '../../../../../hooks/useLocalizations'
import { ProfileContext } from '../../../../../contexts/ProfileContext'
import { useLogger } from '@vp/ubik-context'

type UserNameProps = {
  displayAlert: DisplayAlert;
  onToggle: () => void;
  isOpen: boolean;
}

const UserName = (props: UserNameProps) => {
  const { t } = useLocalization()
  const { profile, updateProfile } = useContext(ProfileContext)

  const [savingName, setSavingName] = useState(false)

  const logger = useLogger()

  const notifySavingOutcome = (firstName: string, serviceError: any) => {
    if (!serviceError) {
      props.displayAlert(
        t('personalInformation.updateNameMessage.success', {
          firstName,
        }),
        true,
        TrackingEventLabels.EDIT_NAME
      )
    } else {
      props.displayAlert(
        t('personalInformation.updateNameMessage.error'),
        false,
        TrackingEventLabels.EDIT_NAME
      )
      logger.error('Error while updating username', serviceError)
    }
  }

  const onNameSave = async (data: any) => {
    trackEvent(TrackingEventLabels.SAVE_NAME_CLICKED)
    setSavingName(true)
    const { serviceError } = await updateProfile(data)

    notifySavingOutcome(data.firstName || profile.firstName, serviceError)

    setSavingName(false)
    props.onToggle()
  }

  return (
    <Section
      data-testid='update-name-link'
      label={t('personalInformation.yourName')}
      canToggle={profile.canManageProfile && !profile.readOnlyMode}
      onToggle={props.onToggle}
      trackingLabel={TrackingEventLabels.EDIT_NAME_CLICKED}
      isOpen={props.isOpen}
      toggleText={t('personalInformation.edit')}
    >
      {{
        whenOpened: (onClose: () => void) => (
          <UpdateInfoForm
            firstName={profile.firstName}
            lastName={profile.lastName}
            hide={onClose}
            onSave={onNameSave}
            savingName={savingName}
          />
        ),

        whenClosed: (
          <Typography data-test-name='customer-name'>
            {profile.firstName} {profile.lastName}
          </Typography>
        ),
      }}
    </Section>
  )
}

export default UserName
