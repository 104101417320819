import { Link, Typography, Spinner, Button } from '@vp/swan'
import { useLocalization } from '../../../../hooks/useLocalizations'

type ButtonsRowProps = {
  onCancel?: () => void;
  updatingPreference: boolean;
  isUpdateButtonDisabled: () => boolean;
  submitPreferenceUpdate: () => void;
}

const ButtonsRow = (props: ButtonsRowProps) => {
  const { t } = useLocalization()

  const updatePreferenceButton = props.updatingPreference
    ? (
      <Spinner accessibleText={t('updatePreferenceButton.accessibilityLabel')} size='standard' my='3' />
      )
    : (
      <Button
        skin='primary'
        data-testid='updateButton'
        marginY='3'
        // eslint-disable-next-line react/jsx-handler-names
        onClick={props.submitPreferenceUpdate}
        disabled={props.isUpdateButtonDisabled()}
      >
        {t('contactPreferences.save')}
      </Button>
      )

  const cancelButton = (
    <Link
      data-testid='cancelButton'
      ml={7}
      component='button'
      className='link-text'
      onClick={(e: any) => {
        e.preventDefault()
        if (props.onCancel) {
          props.onCancel()
        }
      }}
    >
      <Typography textAlign='center'>{t('contactPreferences.cancel')}</Typography>
    </Link>
  )

  return (
    <>
      {updatePreferenceButton}
      {cancelButton}
    </>
  )
}
export default ButtonsRow
