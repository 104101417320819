enum TimeLapse {
  FewSecondsAgo = 30,
  Minute = 60,
  Hour = Minute * 60,
  Day = Hour * 24,
  Month = Day * 28,
  Year = Day * 365,
}

const evaluateLastPasswordChange = (
  t: any,
  lastPasswordChangedOn: string | undefined
) => {
  const [today, lpco] = [Date.now(), Date.parse(lastPasswordChangedOn || '')]
  const checkPlural = (relativeTime: number, subject: string) => {
    const text =
      relativeTime === 1
        ? t(`security.passwordChange${subject}`, { relativeTime })
        : t(`security.passwordChange${subject}s`, { relativeTime })
    // TODO: For Year, return Empty String, if the translations are not available
    if (text.startsWith('security.passwordChangeYear')) return ''
    return text
  }

  // The below code gets the milliseconds elapsed since last password change
  // As per milliseconds elapsed, appropriate message is displayed
  if (lpco) {
    const timeDiff = Math.floor((today - lpco) / 1000)
    return timeDiff >= TimeLapse.Year
      ? checkPlural(Math.floor(timeDiff / TimeLapse.Year), 'Year')
      : timeDiff >= TimeLapse.Month
        ? checkPlural(Math.floor(timeDiff / TimeLapse.Month), 'Month')
        : timeDiff >= TimeLapse.Day
          ? checkPlural(Math.floor(timeDiff / TimeLapse.Day), 'Day')
          : timeDiff >= TimeLapse.Hour
            ? checkPlural(Math.floor(timeDiff / TimeLapse.Hour), 'Hour')
            : timeDiff >= TimeLapse.Minute
              ? checkPlural(Math.floor(timeDiff / TimeLapse.Minute), 'Minute')
              : timeDiff >= TimeLapse.FewSecondsAgo
                ? checkPlural(timeDiff, 'Second')
                : t('security.passwordChangeFewSeconds')
  }
}

export default evaluateLastPasswordChange
