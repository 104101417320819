import { Column, GridContainer, Row } from '@vp/swan'
import { JSX, useContext, useEffect, useState } from 'react'
import { TabIds } from '../../../../common/model/tabIds'
import { getInitialSection } from '../../../../common/services/queryParamsService'
import { TabContext } from '../TabContext'
import Divider from './Divider'
import { useUserContext } from '@vp/ubik-context'

export type SectionGroupChild = (
  onToggle: () => void,
  isOpen: boolean
) => JSX.Element

type SectionGroupProps = {
  children: SectionGroupChild[];
  resetSections: boolean;
  parentTabId: TabIds;
}

const NO_SECTION_OPENED = -1

function SectionGroup (props: SectionGroupProps) {
  const { tryChange } = useContext(TabContext)
  const { queryStrings } = useUserContext()

  const isLastSection = (index: number) => props.children.length - 1 === index

  const onToggle = (sectionId: number) => {
    tryChange(() => applySectionChange(sectionId))
  }

  const INITIAL_SECTION = getInitialSection(props.parentTabId, queryStrings)

  const [openedSection, setOpenedSection] = useState(INITIAL_SECTION)

  const applySectionChange = (key: number | string) =>
    setOpenedSection(
      openedSection === Number(key) ? NO_SECTION_OPENED : Number(key)
    )

  const renderedChildren = props.children.map((c, index) => (
    <div key={index}>
      {c(() => onToggle(index), openedSection === index)}{' '}
      {!isLastSection(index) && <Divider />}
    </div>
  ))

  useEffect(() => {
    if (
      openedSection !== NO_SECTION_OPENED &&
      openedSection !== INITIAL_SECTION
    ) {
      setOpenedSection(NO_SECTION_OPENED)
    }
  }, [props.resetSections])

  return (
    <GridContainer>
      <Row mt='between-actions'>
        <Column span={6}>{renderedChildren}</Column>
      </Row>
    </GridContainer>
  )
}

export default SectionGroup
