import {
  FlexBox,
  Link,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogTitle,
  Button,
  Typography,
} from '@vp/swan'
import { useLocalization } from '../../../hooks/useLocalizations'

type UnsavedChangesReminderProps = {
  onConfirm: () => void;
  onReject: () => void;
  isOpen: boolean;
}

function UnsavedChangesReminder (props: UnsavedChangesReminderProps) {
  const { t } = useLocalization()
  const { isOpen, onConfirm, onReject } = props

  if (!isOpen) return null

  return (
    <ModalDialog
      data-testid='UnsavedChangesReminder-Dialog'
      isOpen={isOpen}
      onRequestDismiss={onReject}
    >
      <ModalDialogContent aria-labelledby='dialog-title'>
        <ModalDialogCloseButton
          visuallyHiddenLabel={t('unsaved-changes-reminder.close')}
        />
        <ModalDialogHeader>
          <ModalDialogTitle>
            <Typography textAlign='center' id='dialog-title'>
              {t('unsaved-changes-reminder.title')}
            </Typography>
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>
          <Typography textAlign='center'>
            {t('unsaved-changes-reminder.body')}
          </Typography>
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <FlexBox
              flexDirection='column'
              alignItems='center'
              style={{ width: '100%' }}
            >
              <Button
                skin='primary'
                onClick={onConfirm}
                data-testid='confirm-unsaved'
              >
                {t('unsaved-changes-reminder.confirm')}
              </Button>
              <Link my='5' onClick={onReject} data-testid='reject-unsaved'>
                <Typography>{t('unsaved-changes-reminder.reject')}</Typography>
              </Link>
            </FlexBox>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export default UnsavedChangesReminder
