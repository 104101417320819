export const getIsCareAgent = () => getParam('isCareAgent') === 'true'

export const getIsComingFromAuth = () => getParam('code') !== null

export function getParam (name: string) {
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(name)
  }
  return null
}

export function getCultureFromLocale (locale: string): string {
  const fragments = locale.split('-')
  return `${fragments[0]}-${fragments[1].toUpperCase()}`
}
