import { useIdentity, useLogger } from '@vp/ubik-context'
import { fetchWithAuthorizationHeaderAsRaw } from '../client/fetchClient'

const ACCOUNT_ID = 'ozoDdrmewShEcbUDWX8J3V'

const createGenericError = (error: string) => {
  return {
    type: 'unknown',
    error: new Error(error),
  }
}

const createWeakPasswordError = (error: string) => {
  return {
    type: 'weakPassword',
    error: new Error(error),
  }
}

const createChangePasswordError = (error: any) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.detail &&
    error.response.data.detail.match(
      'PasswordStrengthError: Password is too weak'
    )
  ) {
    return createWeakPasswordError(error)
  }

  return createGenericError(error)
}

export const useUpdatePassword = () => {
  const { identity } = useIdentity()
  const logger = useLogger()

  const updatePassword = async (newPassword: string): Promise<any> => {
    try {
      return await fetchWithAuthorizationHeaderAsRaw(
      `https://profile.cimpress.io/v1/${ACCOUNT_ID}/profile/me/password`, identity?.authorizationHeader ?? '', {
        body: JSON.stringify({ newPassword }),
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        }
      }
      )
    } catch (error) {
      logger.error({ message: "Error changing user's password.", error })
      throw createChangePasswordError(error)
    }
  }

  return { updatePassword }
}
