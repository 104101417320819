// import { useLogger } from '@vp/ubik-context'
import MyAccountPages from '../../common/model/myAccountPages'

const urlCache: any = {}

// const logger = useLogger()

const getVistaUrl = async (
  page: MyAccountPages,
  tenant: string,
  locale: string
) =>
  getFromCache(page, tenant, locale)
    ? getFromCache(page, tenant, locale)
    : await getUrlFromServer(page, tenant, locale)

const getKey = (page: MyAccountPages, tenant: string, locale: string) =>
  `${tenant}-${locale}-${page}`

const getFromCache = (page: MyAccountPages, tenant: string, locale: string) =>
  urlCache[getKey(page, tenant, locale)]

const updateCache = (
  page: MyAccountPages,
  tenant: string,
  locale: string,
  newUrl: string
) => (urlCache[getKey(page, tenant, locale)] = newUrl)

const getUrlFromServer = async (
  page: MyAccountPages,
  tenant: string,
  locale: string
) => {
  const url = `https://url.prod.merch.vpsvc.com/v3/url/${tenant}/${locale}/${page}?requestor=foundations-account-settings`

  try {
    const request = await fetch(url)
    const result = await request.json() as { url: string }
    return updateCache(page, tenant, locale, result.url)
  } catch (error) {
    console.error({
      message: `Error getting urls for tenant ${tenant}, locale ${locale}, page: ${page}`,
      error,
    })
  }
}

export default getVistaUrl
