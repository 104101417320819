import { useEffect, useState } from 'react'
import {
  ConsentManagerContainer,
  isConsentRequired,
} from '@vp/consent-manager'

import { getWriteKey } from '@vp/tracking-key-provider'
import { Box } from '@vp/swan'
import DisplayAlert from '../../../../common/model/displayAlert'
import Section from '../Shared/Section'
import TrackingEventLabels from '../../../../common/model/trackingEventLabels'
import { trackEvent } from '../../../../common/services/tracking'
import { useLocalization } from '../../../../hooks/useLocalizations'
import { useUserContext } from '@vp/ubik-context'

type ConsentManagerProps = {
  onToggle: () => void;
  isOpen: boolean;
  displayAlert: DisplayAlert;
}

function sleep (ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const ConsentManager = (props: ConsentManagerProps) => {
  const { t } = useLocalization()
  const [trackingWriteKey, setTrackingWriteKey] = useState('')
  const userContext = useUserContext()

  useEffect(() => {
    if (!userContext.locale) return
    let isMounted = true

    const setVariables = async () => {
      const writeKey = await getWriteKey(
        userContext.tenant || 'vistaprint',
        userContext.locale.split('-')[1],
        userContext.environment === 'staging' ? 'dev' : 'prod'
      )
      if (isMounted) {
        setTrackingWriteKey(writeKey)
      }
    }

    setVariables()

    return () => {
      isMounted = false
    }
  }, [userContext])

  if (!isConsentRequired(userContext.locale)) {
    return null
  }

  const delayedOnClosed = async (onClosed: () => void) => {
    trackEvent(TrackingEventLabels.SAVE_COOKIE_SETTINGS_CLICKED)

    await sleep(500)
    onClosed()
    props.displayAlert(
      t('consentManager.updateCookies.success'),
      true,
      TrackingEventLabels.EDIT_COOKIE_SETTINGS
    )
  }

  return (
    <>
      <Section
        label={t('consentManager.cookiesSetting')}
        data-testid='updateCookieSettings'
        canToggle
        onToggle={props.onToggle}
        trackingLabel={TrackingEventLabels.EDIT_COOKIE_SETTINGS_CLICKED}
        isOpen={props.isOpen && !!trackingWriteKey}
        toggleText={t('consentManager.manage')}
      >
        {{
          whenOpened: (onClosed) => (
            <Box mt='5'>
              <ConsentManagerContainer
                writeKey={trackingWriteKey}
                locale={userContext.locale}
                tenant={userContext.tenant || 'vistaprint'}
                suppressReload={false}
                mode='Embedded'
                onAcceptAll={() => delayedOnClosed(onClosed)}
                onSave={() => delayedOnClosed(onClosed)}
              />
            </Box>
          ),
          whenClosed: <></>,
        }}
      </Section>
    </>
  )
}

export default ConsentManager
