const passwordPolicy = () => {
  const isUpperCase = (str: string) => !!str && str !== str.toLocaleLowerCase()
  const isLowerCase = (str: string) => !!str && str !== str.toLocaleUpperCase()
  const isNumber = (str: string) => /^\d+$/.test(str)

  const isFair = (password = ''): boolean => {
    let hasLowerCase
    let hasUpperCase
    let hasNumber = false

    let index = password.length
    while (index-- && !(hasLowerCase && hasUpperCase && hasNumber)) {
      const char = password.charAt(index)
      if (!hasUpperCase) hasUpperCase = isUpperCase(char)
      if (!hasLowerCase) hasLowerCase = isLowerCase(char)
      if (!hasNumber) hasNumber = isNumber(char)
    }
    return (hasUpperCase && hasLowerCase && hasNumber) || false
  }

  const containsUpperCase = (password = '') => {
    let hasUpperCase = false
    let index = password.length
    while (index-- && !hasUpperCase) {
      const char = password.charAt(index)
      if (!hasUpperCase) hasUpperCase = isUpperCase(char)
    }
    return hasUpperCase
  }

  const containsLowerCase = (password = '') => {
    let hasLowerCase = false
    let index = password.length
    while (index-- && !hasLowerCase) {
      const char = password.charAt(index)
      if (!hasLowerCase) hasLowerCase = isLowerCase(char)
    }
    return hasLowerCase
  }

  const containsNumber = (password = '') => {
    let hasNumber = false
    let index = password.length
    while (index-- && !hasNumber) {
      const char = password.charAt(index)
      if (!hasNumber) hasNumber = isNumber(char)
    }
    return hasNumber
  }

  const minimumLength = (password = '', minLength: number) =>
    password.length >= minLength

  return {
    isFair,
    minimumLength,
    containsNumber,
    containsLowerCase,
    containsUpperCase,
  }
}

export default passwordPolicy
