import { EmailPreference } from '../EmailPreferencesSwitch'

const getCucosApiUrl = (environment: string) => {
  if (environment === 'prod') {
    return 'https://cucos.ct.vpsvc.com/api/v2'
  } else {
    return 'https://cucos.dev.ct.vpsvc.com/api/v2'
  }
}

class CucosError extends Error {
  response: Response

  constructor (message: string, response: Response) {
    super(message)
    this.response = response
  }
}

export default class CucosClient {
  private CUCOS_QUERY_ENDPOINT: string
  private CUCOS_MUTATE_ENDPOINT: string
  private QUERY_TEMPLATE: any
  private AUTH_HEADER: any

  constructor (tenant: string, authorizationHeader: string, environment: string) {
    this.CUCOS_QUERY_ENDPOINT = getCucosApiUrl(environment) + '/subscriptions/query'
    this.CUCOS_MUTATE_ENDPOINT = getCucosApiUrl(environment) + '/subscriptions/mutation'
    this.QUERY_TEMPLATE = {
      client: 'contact_preferences_page',
      tenant,
      channel: 'promo_email',
    }
    this.AUTH_HEADER = { Authorization: authorizationHeader }
  }

  async getStoredPreference (userEmail: string) {
    if (!userEmail) {
      return 'none'
    }
    const request = { contact: userEmail }
    const response = await this._post(this.CUCOS_QUERY_ENDPOINT, request)
    return response.preference
  }

  async storePreference (userEmail: string, preference: EmailPreference) {
    const request = { contact: userEmail, preference }
    const response = await this._post(this.CUCOS_MUTATE_ENDPOINT, request)
    return response.preference
  }

  async _post (endpoint: string, values: any): Promise<any> {
    const query = { ...this.QUERY_TEMPLATE, ...values }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...this.AUTH_HEADER,
      },
      body: JSON.stringify(query),
    })

    if (!response.ok) {
      throw new CucosError('HTTP error! ', response)
    }

    return response.json()
  }
}
