enum TrackingEventLabels {
  MY_ACCOUNT_SETTINGS_PERSONAL_INFO_TAB = 'PersonalInfoTab',
  MY_ACCOUNT_SETTINGS_SECURITY_TAB = 'SecurityTab',
  MY_ACCOUNT_SETTINGS_PREFERENCES_TAB = 'PreferencesTab',
  MY_ACCOUNT_SETTINGS_FIND_MY_ORDER = 'Find My Order Link',
  EDIT_NAME_CLICKED = 'Edit Name',
  SAVE_NAME_CLICKED = 'Save Name',
  EDIT_EMAIL_CLICKED = 'Edit eMail',
  SAVE_EMAIL_CLICKED = 'Save eMail',
  EDIT_PASSWORD_CLICKED = 'Edit Password',
  SAVE_PASSWORD_CLICKED = 'Save Password',
  EDIT_CONTACT_PREFERENCES_CLICKED = 'Edit Contact Preferences',
  SAVE_CONTACT_PREFERENCES_CLICKED = 'Save Contact Preferences',
  EDIT_COOKIE_SETTINGS_CLICKED = 'Edit Cookie Settings',
  SAVE_COOKIE_SETTINGS_CLICKED = 'Save Cookie Settings',
  EDIT_DISCONNECT_SOCIAL_ACCOUNT_CLICKED = 'Edit Disconnect Social Account',
  SAVE_DISCONNECT_SOCIAL_ACCOUNT_CLICKED = 'Disconnect Social Account',
  DELETE_ACCOUNT_MODAL_OPENED = 'Delete Account Modal Opened',
  DELETE_ACCOUNT_REQUEST_SUBMITTED = 'Delete Account Request Submitted',

  EDIT_NAME = 'Name Edited',
  EDIT_EMAIL = 'Email Edited',
  EDIT_PASSWORD = 'Password Edited',
  EDIT_CONTACT_PREFERENCES = 'Contact Preferences Edited',
  EDIT_COOKIE_SETTINGS = 'Cookie Settings Edited',
  DISCONNECT_SOCIAL_ACCOUNT = 'Social Account Disconnected',
}
export default TrackingEventLabels
