import { useContext } from 'react'
import DisplayAlert from '../../../../../common/model/displayAlert'
import { TabContext } from '../../TabContext'
import TrackingEventLabels from '../../../../../common/model/trackingEventLabels'
import { trackEvent } from '../../../../../common/services/tracking'
import { useLogger } from '@vp/ubik-context'
import PasswordForm from './PasswordForm'

type PasswordChangeProps = {
  onHide: () => void;
  displayAlert: DisplayAlert;
  onPasswordChanged: () => void;
}

function PasswordChange (props: PasswordChangeProps) {
  const { setSectionAltered } = useContext(TabContext)

  const logger = useLogger()

  const passwordChangeEventListener = (event: any) => {
    if (event['eventType'] === 'hidePasswordFields') {
      props.onHide()
    }
    if (event['eventType'] === 'passwordChangeSuccess') {
      trackEvent(TrackingEventLabels.SAVE_PASSWORD_CLICKED, 'Success')
      props.onPasswordChanged()
      props.displayAlert(
        event.message,
        true,
        TrackingEventLabels.EDIT_PASSWORD
      )
      props.onHide()
    }
    if (event['eventType'] === 'passwordChangeFailure') {
      trackEvent(TrackingEventLabels.SAVE_PASSWORD_CLICKED, 'Failure')
      props.displayAlert(
        event.message,
        false,
        TrackingEventLabels.EDIT_PASSWORD
      )
      logger?.error('Error updating password', event.message)
    }
    if (event['eventType'] === 'passwordSectionChanged') {
      setSectionAltered(event.message)
    }
  }

  return (
    <PasswordForm
      key='passwordForm'
      data-testid='passwordChangeForm'
      onEmitEvent={passwordChangeEventListener}
    />
  )
}

export default PasswordChange
