import { Column, GridContainer, H2, Row } from '@vp/swan'
import ContactPreferencesForm from './ContactPreferencesForm'
import { EmailPreference } from './EmailPreferencesSwitch'
import { useLocalization } from '../../../../hooks/useLocalizations'

type ContactPreferencesProps = {
  userEmail: string;
  onCancel: () => void;
  onSaveClicked: (oldStatus: EmailPreference, newStatus: EmailPreference) => void;
  onSendFeedback: (content: string, isSuccess?: boolean) => void;
  onAltered: (altered: boolean) => void;
}

function ContactPreferencesUpdater (props: ContactPreferencesProps) {
  const { t } = useLocalization()
  return (
    <>
      <GridContainer>
        <Row>
          <Column span={12}>
            <H2 fontSkin='title-section'>{t('contactPreferences.title')}</H2>
          </Column>
        </Row>
      </GridContainer>
      <ContactPreferencesForm
        userEmail={props.userEmail}
        onCancel={props.onCancel}
        onSaveClicked={props.onSaveClicked}
        onSendFeedback={props.onSendFeedback}
        onAltered={props.onAltered}
      />
    </>
  )
}

const ContactPreferencesUpdaterWithTranslations = (props: ContactPreferencesProps) => {
  return (
    <ContactPreferencesUpdater {...props} />
  )
}

export default ContactPreferencesUpdaterWithTranslations
