import React, { useState } from 'react'
import UnsavedChangesReminder from './UnsavedChangesReminder'

const initialValue = {
  sectionAltered: false,
  setSectionAltered: (altered: boolean) => {},
  tryChange: (applyChange: () => void) => {},
  _contextName: 'TabContext',
}

export const TabContext = React.createContext(initialValue)

type TabContextProviderProps = {
  children: any;
}

export const TabContextProvider = (props: TabContextProviderProps) => {
  const [sectionAltered, setSectionAltered] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [applyChangeCallback, setApplyChangeCallback] = useState<any>(() => {})

  const setSection = (changed: boolean) => {
    setSectionAltered(changed)
    if (!changed) hideConfirmDialog()
  }

  const hideConfirmDialog = () => setShowConfirmDialog(false)

  const tryChange = (applyChange: () => void) => {
    const applyChangeAndReset = () => {
      applyChange()
      setSectionAltered(false)
      setShowConfirmDialog(false)
    }

    setApplyChangeCallback(() => applyChangeAndReset)
    if (sectionAltered) {
      setShowConfirmDialog(true)
    } else {
      applyChangeAndReset()
    }
  }

  const value = {
    sectionAltered,
    setSectionAltered: setSection,
    tryChange,
    _contextName: initialValue._contextName,
  }

  return (
    <>
      <TabContext.Provider value={value}>
        <UnsavedChangesReminder
          onConfirm={() => {
            applyChangeCallback()
          }}
          onReject={hideConfirmDialog}
          isOpen={showConfirmDialog}
        />
        {props.children}
      </TabContext.Provider>
    </>
  )
}
