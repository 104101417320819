const CareTeamUrlCache: any = {}

const getCareTeamUrl = async (page: string, tenant: string, locale: string) => {
  const cacheResult = getFromCache(page, tenant, locale)
  return cacheResult || getUrlFromServer(page, tenant, locale)
}

const getKey = (page: string, tenant: string, locale: string) =>
  `${page}-${tenant}-${locale}`

const getFromCache = (page: string, tenant: string, locale: string) =>
  CareTeamUrlCache[getKey(page, tenant, locale)]

const updateCache = (
  page: string,
  tenant: string,
  locale: string,
  newUrl: string
) => (CareTeamUrlCache[getKey(page, tenant, locale)] = newUrl)

const getUrlFromServer = async (
  page: string,
  tenant: string,
  locale: string
) => {
  const url = `https://channel-provider-service.care.vpsvc.com/v2/${page}?tenant=${tenant}&locale=${locale}&requestor=foundations-account-settings`

  try {
    const request = await fetch(url)
    const result = await request.json() as { url: string }

    return updateCache(page, tenant, locale, result.url)
  } catch (error) {
    console.error({
      message: `Error getting urls for tenant ${tenant}, locale ${locale}, page: ${page}`,
      error,
    })
  }
}

export default getCareTeamUrl
