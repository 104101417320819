import { Typography, Link } from '@vp/swan'
import MyAccountPages from '../../../../common/model/myAccountPages'
import useUrl from '../../../../hooks/useUrl'
import { trackEvent } from '../../../../common/services/tracking'
import TrackingEventLabels from '../../../../common/model/trackingEventLabels'
import { useLocalization } from '../../../../hooks/useLocalizations'
import { Trans } from '../Shared/Trans'

function GoToPayments () {
  const paymentAndDeliveryUrl = useUrl(MyAccountPages.PaymentDelivery)
  const { t } = useLocalization()

  return (
    <Typography fontSize='small' textAlign='left' textColor='subtle'>
      <Trans
        resourceKey='personalInformation.payment-and-delivery-text'
        tokens={{
          goToPaymentAndDelivery: t(
            'personalInformation.payment-and-delivery-link'
          ),
        }}
        components={[
          <Link
            key='paymentAndDeliveryLink'
            data-testid='invalid-document-help-button'
            href={paymentAndDeliveryUrl}
            onClick={() =>
              trackEvent(TrackingEventLabels.MY_ACCOUNT_SETTINGS_FIND_MY_ORDER)}
          >
            goToPaymentAndDelivery
          </Link>
        ]}
      />
    </Typography>
  )
}

export default GoToPayments
