import { FetchStatus, QueryStatus } from '@tanstack/react-query'

export interface FetchResponse<T> {
  data: T | undefined;
  isLoading: boolean;
  error: unknown;
  refetch: () => void;
  fetchStatus: FetchStatus;
  status: QueryStatus;
}

const MAX_RETRIES = 3

export const fetchWithAuthorizationHeaderAsJson = async (
  request: Request | string,
  authorizationHeader: string,
  requestInit: RequestInit = {}
) : Promise<any> => {
  const response = await performFetchRequest(request, requestInit, authorizationHeader)
  return response.json()
}

export const fetchWithAuthorizationHeaderAsRaw = async (
  request: Request | string,
  authorizationHeader: string,
  requestInit: RequestInit = {}
) : Promise<Response> => {
  const response = await performFetchRequest(request, requestInit, authorizationHeader)
  return response
}

export class FetchError extends Error {
  constructor (message: string, public readonly status: number, public readonly url: string) {
    super(message)
    this.name = 'FetchError'
  }
}

export const queryRetry = (failureCount: number, error: unknown): boolean => {
  if (failureCount > MAX_RETRIES) return false

  if (error instanceof FetchError) {
    return error.status < 400 || error.status >= 500
  }

  return false
}
async function performFetchRequest (request: string | Request, requestInit: RequestInit, authorizationHeader: string) {
  const response = await fetch(request, {
    ...requestInit,
    headers: {
      ...requestInit.headers,
      Authorization: authorizationHeader,
    },
  })

  if (!response.ok) {
    const requestUrl = request instanceof Request ? request.url : request
    throw new FetchError(
      `Network response for ${requestUrl} was ${response.status}`,
      response.status,
      requestUrl
    )
  }
  return response
}
