import React, { useState } from 'react'
import {
  TextInput,
  TextInputWithFloatingLabel,
  TextInputFloatingLabel,
  Button,
  Icon,
  FormError,
  FlexBox,
} from '@vp/swan'

type PasswordInputProps = {
  label: string
  value: string
  onChange: (event: { target: { value: any } }) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  validator?: () => boolean
  errorText: string
  'data-testid'?: string
}

function PasswordInput (props: PasswordInputProps) {
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  return (
    <FlexBox flexDirection='column' style={{ width: '100%' }}>
      <TextInputWithFloatingLabel>
        <TextInput
          data-testid={props['data-testid']}
          type={isPasswordVisible ? 'text' : 'password'}
          value={props.value}
          autoComplete='off'
          autoCapitalize='off'
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
        <TextInputFloatingLabel>{props.label}</TextInputFloatingLabel>
        <Button onClick={() => setPasswordVisible(!isPasswordVisible)}>
          <Icon
            iconType={isPasswordVisible ? 'passwordHide' : 'passwordShow'}
          />
        </Button>
      </TextInputWithFloatingLabel>
      {props.validator && !props.validator() && (
        <FormError data-testid={props['data-testid'] + '-error'}>{props.errorText}</FormError>
      )}
    </FlexBox>
  )
}

export default PasswordInput
