import React, { useContext, useState } from 'react'
import { Spinner, Link, Box, StandardForm, Button, Typography } from '@vp/swan'
import FormTextBox from '../../Shared/FormTextBox'
import { TabContext } from '../../TabContext'
import { useLocalization } from '../../../../../hooks/useLocalizations'

const isNameValid = (value = '') => value.trim().length >= 1

type UpdateInfoFormProps = {
  firstName: string;
  lastName: string;
  onSave: (data: {
    firstName: string | undefined;
    lastName: string | undefined;
  }) => void;
  savingName: boolean;
  hide: () => void;
}

const UpdateInfoForm: React.FC<UpdateInfoFormProps> = (props) => {
  const { t } = useLocalization()
  const { setSectionAltered } = useContext(TabContext)

  const [firstName, setFirstName] = useState({
    value: props.firstName,
    isModified: false,
  })
  const [lastName, setLastName] = useState({
    value: props.lastName,
    isModified: false,
  })

  const onFirstNameChange = (event: any) => {
    const value = event.target.value
    const isModified = props.firstName !== value
    setSectionAltered(isModified || lastName.isModified)
    setFirstName({ value, isModified })
  }

  const onLastNameChange = (event: any) => {
    const value = event.target.value
    const isModified = props.lastName !== value
    setSectionAltered(isModified || firstName.isModified)
    setLastName({ value, isModified })
  }

  const shouldSave = () => {
    const isNameModified = firstName.isModified || lastName.isModified
    return (
      isNameModified &&
      isNameValid(firstName.value) &&
      isNameValid(lastName.value)
    )
  }

  const onNameSave = async (event: any) => {
    event.preventDefault()
    return props.onSave({
      firstName: firstName.isModified ? firstName.value.trim() : undefined,
      lastName: lastName.isModified ? lastName.value.trim() : undefined,
    })
  }

  return (
    <StandardForm className='update-name' noValidate>
      <FormTextBox
        data-testid='firstName-change-input'
        label={t('personalInformation.input.firstName')}
        value={firstName.value}
        onChange={onFirstNameChange}
        validator={isNameValid}
        errorText={t('personalInformation.input.firstNameValidation')}
      />
      <FormTextBox
        data-testid='lastName-change-input'
        label={t('personalInformation.input.lastName')}
        value={lastName.value}
        onChange={onLastNameChange}
        validator={isNameValid}
        errorText={t('personalInformation.input.lastNameValidation')}
      />
      <Box>
        <Button
          mr='between-actions'
          skin='primary'
          type='submit'
          disabled={!shouldSave() || props.savingName}
          onClick={onNameSave}
        >
          {props.savingName && (
            <Spinner accessibleText={t('loading')} size='tiny' />
          )}
          {t('personalInformation.save')}
        </Button>
        <Link
          component='button'
          className='link-text'
          onClick={(e: any) => {
            e.preventDefault()
            props.hide()
          }}
        >
          <Typography textAlign='center'>
            {t('personalInformation.cancel')}
          </Typography>
        </Link>
      </Box>
    </StandardForm>
  )
}

export default UpdateInfoForm
