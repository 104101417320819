import { Typography } from '@vp/swan'
import React from 'react'
import { useLocalization } from '../../../../hooks/useLocalizations'
import { getDiscountPerCountry } from '../../../../common/services/MiscHelpers'

type ContactPreferencesDescriptionProps = {
  tenant: string;
  locale: string;
}

const boldTag = '#BOLD#'

const ContactPreferencesDescription = ({ tenant, locale }: ContactPreferencesDescriptionProps) => {
  const { t } = useLocalization()
  const country = locale.split('-')[1].toUpperCase()

  const discount = getDiscountPerCountry(tenant, country)

  if (discount === '0' || !discount) {
    return <>{t('contactPreferences.descriptionWithOutOffer')}</>
  }

  const descriptionWithOffer = t('contactPreferences.descriptionWithOffer', { discount: boldTag }).split(boldTag)

  return (
    <>
      {descriptionWithOffer.map((part: string, index: number) => {
        if (index === 1) {
          return (
            <React.Fragment key={index}>
              <Typography fontSkin='body-standard-bold' display='inline'>
                {t('contactPreferences.descriptionDiscount', { discount })}
              </Typography>
              {part}
            </React.Fragment>
          )
        }
        return part
      })}
    </>
  )
}

export default ContactPreferencesDescription
